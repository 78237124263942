import { isNullOrUndefined } from 'util';
import { Caption, Category, Chart, Dataset, FusionChart, Subcaption, Yaxis } from './../modal/fusion.chart.modal';
export class ChartUtils {
    public static createDataSource(label: Array<any>, seriesNames: Array<string>,
        data: Array<Array<any>>, chart: Chart, caption: Caption, subcaption: Subcaption,
        yaxis: Array<Yaxis>): FusionChart {
        const categories: Category[] = [{
            category: label.map(x => ({ label: String(x) }))
        }];
        const dataSet: Dataset[] = [];
        data.forEach((value, index) => {
            dataSet.push({
                seriesname: seriesNames[index],
                data: value.map(x => ({ value: x }))
            });
        });
        const fusionChart: FusionChart = {
            chart: chart,
            categories: categories,
            dataset: dataSet
        };
        if (!isNullOrUndefined(caption)) {
            fusionChart.caption = caption;
        }
        if (!isNullOrUndefined(subcaption)) {
            fusionChart.subcaption = subcaption;
        }
        if (!isNullOrUndefined(yaxis)) {
            fusionChart.yAxis = yaxis;
        }
        return fusionChart;
    }
}
