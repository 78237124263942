export class ReportUtils {

    static getStatusCode(status: string): number {
        let statusCode = 0;
        switch (status) {
            case 'Docs Created': statusCode = 2; break;
            case 'Docs Sent to Arrow': statusCode = 3; break;
            case 'Ship Confirmed': statusCode = 85; break;
            case 'Hold Order': statusCode = 93; break;
            case 'Awaiting Priority Release': statusCode = 15; break;
            case 'Cancel Requested': statusCode = 95; break;
        }
        return statusCode;
    }
}
